<template>
  <div>
    <div class="sector__card mb-32 top__content">
      <p>
        {{
          $store.state.sectors[this.type]
            ? $store.state.sectors[this.type].longText
            : ""
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: false },
    type: { type: String, required: true },
  },
  methods: {
    formModalOn() {
      this.$store.dispatch("setModalForm", true);
    },
  },
};
</script>

<style scoped lang="scss">
p {
  font-weight: 500;
}
</style>
